.half-split-large {
	margin-bottom: 48px;
	flex: 1;
	order: 1;
	/* grid-row-start: 1;
	grid-row-end: 3; */
}

.half-split-small {
	margin-bottom: 48px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	gap: 191px;
	order: 2;

	/* grid-column-start: 2;
	grid-row-start: 2; */
}


.print-container {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	/* border: 3px dotted red; */

}

.print-container>div {
	display: flex;
	align-items: center;
	justify-content: center;
	/* font-family: SFL, -apple-system, BlinkMacSystemFont, sans-serif; */


}

.print_space {
	background-color: white;
	/*box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;*/
}

.print-size {
	text-align: center;
	color: #48435C;
	/* font-family: sans-serif; */
	font-size: 15px;
	font-weight: 300;
	margin-top: 20px;
	margin-bottom: 8px;

}

.small-print {
	height: 290px;
	width: 224px;
	/* height: 440px;
	width: 340px; */
}

.large-print {
	height: 580px;
	width: 448px;
	/* height: 880px;
	width: 680px; */
}

.NFTPrintBlock {
	display: flex;
	/* grid-template-columns: 1fr 1fr; */
	/* grid-column-gap: 64px; */
	gap: 64px;
	align-items: end;
	flex-wrap: wrap;
	align-items: flex-start;
	/* border-bottom: 1px solid rgb(120, 120, 120); */
}

@media only screen and (max-width: 830px) {
	.NFTPrintBlock {
		margin-right: 48px;
	}

	.half-split-large {
		order: 2;
	}

	.half-split-small {
		gap: unset;
		order: 1;
	}

	.print-container {
		align-items: center;
	}
}



.print-container>div>img {
	max-height: 80%;
	max-width: 80%;
}

.print-price {
	text-align: right;
	font-size: 1.5rem;
	margin-right: 20px;
}

.nft-name {
	margin-bottom: 20px;
	color: #48435C;
}

.nft-name-display {
	margin-bottom: 20px;
	width: 100%;
	color: #48435C;
	padding-top: 24px;
	padding-bottom: 24px;
	padding-left: 24px;
	background-color: #FCEDE3;
	font-weight: 600;
	/* grid-column-start: 2; */
}

.nft-name-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}

.quantity-label {
	font-weight: 500;
	margin-bottom: 20px;
	color: #48435C;
}