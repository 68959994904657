.wallet-connection-button-container {
	color: FAF1EA !important;
	background-color: #48435C !important;
	font-weight: 500 !important;
	padding: 0 64px !important;
	-webkit-transition: all .15s ease-in-out !important;
	transition: all .15s ease-in-out !important;
}

.wallet-connection-button-container:hover{
	box-shadow: 0 0 10px 0 #B7ACB9 inset, 0 0 20px 2px #B7ACB9 !important;
	background-color: #B7ACB9 !important;
}

.wallet-button{
	display: flex;
	margin: 3rem;
	min-height: calc(100vh - 6rem);
	align-items: center;
	justify-content: center;
}