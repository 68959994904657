.shipping-modal{
	height: 94vh !important;
    width: 50vw !important;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
}

.order-number{
    font-size: 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 500;
    color: #A29CBA;
    padding-top: 16px;
    text-align: center;
}

.confirmation-text{
    font-size: 16px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    color: #48435C;
    text-align: center;
}

.order-received{
  text-align: center;
}

.fadeInUp{animation:fadeInUp 1s ease backwards;}

@keyframes fadeInUp{
  0%{transform:translate(0px, 32px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}

.fadeInDown{animation:fadeInDown 1s ease backwards;}

@keyframes fadeInDown{
  0%{transform:translate(0px, -32px); opacity: 0;}
  100%{transform:translate(0px, 0); opacity: 1;}
}