.cart-container {
	width: 587px;
	position: relative;
}

.cart-background-main {
	width: 561px;
	height: 100%;
	background-color: #FFE4D5;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 4;
}

.cart-background2 {
	width: 574px;
	height: 100%;
	background-color: #FDE8DC;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 3;
}

.cart-background1 {
	width: 587px;
	height: 100%;
	background-color: #FCEDE3;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
}

.Cart {
	position: fixed;
	overflow-y: auto;
	max-height: calc(100vh - 249.59px);
}

.css-1ewzmbr {
	gap: 64px;
}

.Cart::-webkit-scrollbar {
	width: 24px;

}

.Cart::-webkit-scrollbar-thumb {
	box-shadow: inset 0 0 16px 16px #ccb6aa;
	border: solid 8px transparent;
	border-radius: 100px;
}

.Cart::-webkit-scrollbar-track {
	box-shadow: inset 0 0 16px 16px #FFE4D5;
	border: solid 10px transparent;

}

.Cart::-webkit-scrollbar-button {
	display: none;
}

.cart-title {
	margin-bottom: 32px;
	margin-top: 24px;
}

.cart-previews-container {
	margin-left: 48px;
	margin-right: 48px;
}

.cart-previews {
	display: flex;
	flex-direction: column;
	height: calc(100% - 178.59px);
}

.css-x5jxuw {
	border-radius: 15px !important;
	max-height: 94vh !important;
	width: auto !important;
	border: none !important;
	padding: 0 !important;
	overflow: hidden !important;
}

.filler {
	height: 48px;
}

.counter-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-top: 8px;
}

.counter-wrapper {
	padding-left: 8px;
	padding-right: 4px;
	color: #A29CBA;
	font-size: 16px;
	font-weight: 400;
	font-family: Helvetica;
}

.checkout-modal {
	display: flex;
	flex-direction: row;
	max-height: 94vh;
	max-width: 94vw;
}



.payment-summary {
	margin-bottom: 48px;
}

.AddressForm {
	min-width: 572px;
	padding: 42px;
	border-right: 1px solid;
	border-color: rgba(191, 171, 161, 0.2)
}

.payment-modal {
	min-width: 572px;
	padding: 32px;
	overflow-y: scroll;
}

.MuiInputBase-input {
	padding: 6px 0 12px !important;
}

.css-2b097c-container {
	margin-top: 32px !important;
	margin-bottom: 8px !important;
}

.input-label {
	color: #48435C !important;
	font-size: 14px !important;
}

.css-1wa3eu0-placeholder {
	font-size: 14px !important;
	color: #48435C !important;
}

.payment-modal-buttons {
	display: flex;
	flex-direction: column;
	margin-bottom: 48px;
}

.or {
	text-align: center;
	color: #A29CBA;
	margin-top: 16px;
	margin-bottom: 16px;
	font-size: 16px;
	font-weight: 600;
	font-family: Helvetica;
}

.payment-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.pay-with {
	margin-bottom: 32px;
}

.sol-update {
	text-align: center;
	color: #A29CBA;
	font-size: 16px;
	font-weight: 400;
	font-family: Helvetica;
}

.MuiIcon-root {
	display: flex;
	align-items: center;
	overflow: visible !important;
}

.table-title {
	color: #FFF;
	font-weight: 600;
}

.table-content {
	font-size: 12px;
}

#show-cart-button {
	display: none;
}

.tax-shipping {
	font-size: 12px;
	color: #A29CBA;
	padding-top: 16px;
	padding-bottom: 16px;
	border-width: 1px 0px;
	border-style: solid;
	border-color: rgba(191, 171, 161, 0.2)
}

#checkout-button {
	background-color: #48435C;
}

#show-cart-button {
	background-color: #48435C;
}

@media only screen and (max-width: 1371px) {
	.cart-receipt-container {
		/* position: fixed;
		bottom: 0; */
		width: 100%;
	}

	/* #checkout-button {
		display: none;
	} */

	#show-cart-button {
		display: flex;
	}

	.hide {
		display: none;
	}

	.checkout-modal {
		flex-direction: column;
		overflow-y: scroll;
	}

	.payment-modal {
		overflow-y: unset;
	}


}

@media only screen and (max-width: 830px) {
	.checkout-modal {
		max-width: none;
		min-height: calc(100vh - calc(100vh - 90%)) !important;
		max-height: none !important;
	}

	.css-x5jxuw {
		min-height: calc(100vh - calc(100vh - 90%)) !important;
	}

	.Cart {
		max-height: calc(100vh - calc(100vh - 79%));
		width: 100%;
	}

	.cart-background-main {
		position: fixed;
		width: 100%;
	}

}