.cart-mini-print {
	height: 165px;
	width: 128px;
}

.cart-modifiers {
	flex-grow: 1;
	flex-basis: 0;
	display: flex;
	flex-direction: column;
}

.cart-preview {
	display: grid;
	grid-template-columns: 1fr 2fr;
	grid-column-gap: 64px;
	margin-bottom: 56px;
	margin-top: 24px;
	line-height: 150%;
}

.cart-print-container {
	display: flex;
	flex-grow: 1;
	flex-basis: 0;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}


.cart-print-container>div {
	display: flex;
	align-items: center;
	justify-content: center;
}

.cart-print-container>div>img {
	max-height: 80%;
	max-width: 80%;
}

.cart-print-size {
	color: #48435C;
	font-size: 15px;
	font-weight: 300;
}

.cart-small-modifer{
	margin-top:auto;
}


.cart-quantity-price {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 8px;
}


.cart-total {
	/* margin-right: 30px; */
	font-size: 18px;

}