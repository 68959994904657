.receipt-size-label {
	display: flex;
	/* margin-right: 30px; */
	justify-content: space-between;
	flex-wrap: wrap;

}

.css-rda2r7-MuiFormControlLabel-root{
	margin-top: 8px !important;
	margin-left: -12px !important;

}

.css-dmmspl-MuiFormGroup-root{
	margin-top: 24px !important;
}

.receipt-size-quantity-label {
	flex: 1;
}

.receipt-size-total-label {
	flex-grow: 1;
	flex-basis: 0;
	text-align: right;
}

.cart-receipt {
	border-bottom: 2px dotted rgb(132, 132, 132);
}

.cart-receipt>div {
	margin-top: 20px;
}

.cart-receipt-container {
	background: #FFE4D5;
	font-size: 20px;
	font-weight: 600;
	position: fixed;
	bottom: 0;
	right: 0;
	width: 561px;
	box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.05);
	z-index: 10;
}

.receipt-container {
	margin-right: 48px;
	margin-left: 48px;
	margin-bottom: 24px;
}

.receipt-total.tax-shipping {
	margin-top: 16px;
	margin-bottom: 16px;
}

.receipt-total.payment-total {
	margin-top: 16px;
	margin-bottom: 16px;
}

.tax-shipping {
	font-size: 12px;
	color: #A29CBA;
	padding-top: 16px;
	padding-bottom: 16px;
	border-width: 1px 0px;
	border-style: solid;
	border-color: rgba(191, 171, 161, 0.2)
}

.payment-total {
	font-family: Helvetica, sans-serif;
	font-weight: 600;
}

.receipt-total {
	display: flex;
	/* margin-right: 30px; */
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 32px;
}

.receipt-total-label {
	flex-grow: 1;
	flex-basis: 0;
}

.receipt-total-value {
	flex-grow: 1;
	flex-basis: 0;
	text-align: right;
}

a {
	text-decoration: none;
	color: rgb(63, 90, 213);
}