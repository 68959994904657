.NFTViewer {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	flex: 7;
	margin-left: 48px;
	margin-top: 24px;
}

.selection-title{
	margin-bottom: 32px;
}

@media only screen and (max-width: 1371px) {
	.NFTViewer{
		margin-bottom: 40vh;
	}
}